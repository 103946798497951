<template>
  <el-container id="login">
    <el-main class="main">
      <div class="middle">
        <el-row type="flex" class="row-bg" justify="center" align="middle">
          <el-col :sm="15" :md="8" :lg="5" v-show="type == 'login'">
            <div class="mainForm-title">登&nbsp;录</div>
            <div class="mainForm">
              <el-form
                ref="loginForm"
                label-width="100px"
                label-position="top"
                :model="loginForm"
                :rules="rules"
              >
                <el-form-item label="用户名" prop="username" class="user">
                  <el-input
                    v-model="loginForm.username"
                    auto-complete="off"
                    placeholder="请输入用户名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" class="pass">
                  <el-input
                    id="password"
                    type="password"
                    v-model="loginForm.password"
                    auto-complete="off"
                    placeholder="请输入密码"
                    @keyup.enter.native="handleEnterClick"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div
                    style="display: flex;justify-content: space-between;width: 100%;"
                  >
                    <el-button
                      id="enterBtn"
                      type="primary"
                      style="width:100%;"
                      @click="submit('loginForm')"
                      :disabled="loginBtnDisabled"
                      >登录</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-main>
    <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2023035798号</a>
  </el-container>
</template>

<script>
import { login, hasAdmin } from "../service.js";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      registerForm: {
        username: "",
        password: "",
        passwordRepeat: "",
        nickName: "",
      },
      loginBtnDisabled: false,
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      type: "login",
    };
  },
  beforeCreate() {
    // hasAdmin({
    //   username: 'admin',
    //   password: '123456',
    //   role: '0'
    // }).then(res => {
    //   console.log(res)
    // })
  },
  mounted() {
  },
  methods: {
    // 密码框 enter 登录
    handleEnterClick() {
      // 获取密码框并让其失焦
      var child = document.getElementById("password").children[0];
      child.blur();
      document.getElementById("enterBtn").click();
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginBtnDisabled = true;
          let params = {
            username: this.loginForm.username,
            password: this.loginForm.password,
          };
          login(params).then((data) => {
            if (data.code === 200) {
              localStorage.setItem(
                "userInfo",
                JSON.stringify({
                  ...data.user,
                  token: "Bearer " + data.token,
                })
              );
              this.$message.success("登录成功！");
              
              localStorage.setItem('currentIndex', 'court')
              this.$router.push({
                path: "/court",
              });
              localStorage.setItem("username", this.loginForm.username);
            } else {
              this.$message.error(data.msg[0].msg);
            }
            this.loginBtnDisabled = false;
          });
        }
      });
    },
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(valid);
          // 判断两次密码是否一致
          console.log(this.registerForm);
          if (this.registerForm.password !== this.registerForm.passwordRepeat) {
            this.$message.error("两次密码不一致");
            return;
          }
          this.loginBtnDisabled = true;
          let params = {
            username: this.registerForm.username,
            password: this.registerForm.password,
            nickname: this.registerForm.nickname,
            plans: "",
          };
          console.log(params);
          register(params).then((data) => {
            if (data.code === 200) {
              this.type = "login";
              this.loginBtnDisabled = false;
              this.$message.success("注册成功，请登录");
              this.$refs[formName].resetFields();
            } else {
              this.$message.error(data.msg[0].msg);
            }
            this.loginBtnDisabled = false;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#login {
  background: url("../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
#login .heade {
  padding-top: 15px;
}
#login .headImg {
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
  height: 30px;
  width: 104px;
}
#login .headImg > img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
#login .middle {
  position: relative;
  top: 50%;
  margin-top: -185px;
}
#login .mainTitle {
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}
#login .mainForm-title {
  background: #409EFF;
  color: #fff;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#login .mainForm {
  padding: 3% 10%;
  background: #ffffff;
  opacity: 0.9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
#login .el-form--label-top .el-form-item__label {
  padding: 0 12px 0 0;
}
#login .user.el-form-item.is-required .el-form-item__label:before {
  content: url("../assets/img/user.png");
}
#login .pass.el-form-item.is-required .el-form-item__label:before {
  content: url("../assets/img/pass.png");
}

.back {
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
}
.beian {
  width: 10%;
  text-align: center;
  position: fixed;
  bottom: 20px;
  color: #000;
  left: 45%;
}
</style>
