<template>
  <div class="model">
    <div class="flex-between">
      <div class="flex-center">
        <div class="flex-center">
          <div>选择日期</div>
          <el-date-picker
            v-model="date"
            size="mini"
            :disabled="orderMode || closeMode"
            :clearable="false"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="queryData"
          >
          </el-date-picker>
        </div>
        <el-button
          :disabled="orderMode || closeMode"
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          style="margin-left: 0;"
          @click="queryData"
          >刷新</el-button
        >
      </div>
      <div class="operation-btns">
        <el-button
          v-if="!orderMode"
          size="mini"
          type="danger"
          @click="orderArea"
          >订场</el-button
        >
        <el-button
          v-if="orderMode"
          size="mini"
          type="warning"
          @click="cancelOrder"
          >退出订场</el-button
        >
        <el-button
          v-if="!closeMode"
          size="mini"
          type="danger"
          @click="closeArea"
          >锁定</el-button
        >
        <el-button
          v-if="closeMode"
          size="mini"
          type="warning"
          @click="cancelClose"
          >退出锁定</el-button
        >
        <el-button
          v-if="closeMode || orderMode"
          size="mini"
          type="primary"
          @click="submit"
          >提交</el-button
        >
      </div>
    </div>

    <div class="legend flex-center">
      <div v-for="item of legend" class="flex-center">
        <div class="legend-color" :style="{ background: item.color }"></div>
        <div class="legend-title">{{ item.title }}</div>
      </div>
    </div>

    <div class="content">
      <div class="content-inner">
        <div class="time-line">
          <div v-for="time of timeLine" class="block-timeline"
          :style="{
              height: isChange ? '31.5px' : '33.5px',
            }"
            >
            {{ time }}
          </div>
        </div>
        <div v-for="item of areas">
          <div class="block block-date" style="height: 32px;line-height: 32px;"
          >
            {{ item.areaId }}
            <i
              v-if="closeMode"
              class="el-icon-lock"
              @click="closeByDate(item)"
            ></i>
          </div>
          <div
            class="block"
            :style="{
              background: legend.find((v) => v.status == block.status).color,
              height: isChange ? '30px' : '32px',
              lineHeight: isChange ? '30px' : '32px',
            }"
            v-for="(block, index) of item.area"
            @click="clickEachArea(item, index)"
          >
            <div v-if="block.orderPhone">
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
              >
                <div slot="content">预定人电话：{{ block.orderPhone }}<br/>姓名：{{block.orderUser}}</div>
                <span style="color: #fff;">{{
                  legend.find((v) => v.status == block.status).title
                }}</span>
              </el-tooltip>
            </div>
            <div v-if="!block.orderPhone">
              <span v-if="!orderMode || block.status == 2">{{
                legend.find((v) => v.status == block.status).title
              }}</span>
              <i
                v-if="orderMode && block.status == 1"
                style="color: #fff;"
                class="el-icon-check"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="订场"
      :visible.sync="orderDailog"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-radio-group size="mini" v-model="orderType" class="mb20">
        <el-radio-button label="会员"></el-radio-button>
        <el-radio-button label="散客"></el-radio-button>
      </el-radio-group>
      <div>
        <div class="flex-center" v-if="orderType == '会员'">
          <div class="label">手机号：</div>
          <el-select
            v-model="dialogData.phone"
            filterable
            size="mini"
            remote
            reserve-keyword
            placeholder="请输入手机号"
            :remote-method="remoteMethod"
            @change="chooseUser"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.phone"
              :label="item.phone"
              :value="item.phone"
            >
            </el-option>
          </el-select>
          <div class="label">姓名：</div>
          <el-select
            v-model="dialogData.username"
            filterable
            size="mini"
            remote
            reserve-keyword
            placeholder="请输入昵称"
            :remote-method="remoteMethod2"
            @change="chooseUser2"
            :loading="loading"
          >
            <el-option
              v-for="item in options2"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mt20 flex-center" v-if="orderType == '散客'">
          <div class="label">手机号：</div>
          <el-input
            size="mini"
            style="width: 180px"
            v-model="dialogData2.phone"
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="mt20 flex-center" v-if="orderType == '散客'">
          <div class="label">姓名：</div>
          <el-input
            size="mini"
            style="width: 180px"
            v-model="dialogData2.username"
            placeholder="请输入姓名"
          ></el-input>
        </div>
        <div class="mt20 flex-center">
          <div class="label">订单金额</div>
          <div style="font-weight: bold;color: red;">{{ currentCost }}元</div>
        </div>
        <div
          class="mt20 flex-center"
          v-if="orderType == '会员' && dialogData.phone"
        >
          <div class="label">余额：</div>
          <div>{{ dialogData.surplus }}</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="orderDailog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="order" size="mini">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
import {
  addOrder,
  addOrderCommon,
  getOrderByDate,
  addClose,
  getCloseByDate,
  updateClose,
  getUser,
  getSetting,
  clearCloseArea,
  getRecharge
} from "../service.js";
import { debounce } from "../tools";
export default {
  data() {
    return {
      date: new Date(), // 默认展示今天
      orderDailog: false, // 预定选择人的弹窗
      areas: [],
      timeLine: [],
      legend: [
        {
          title: "未预定",
          color: "#a4dc6b",
          status: 0,
        },
        {
          title: "已预订",
          color: "#ef6c3c",
          status: 1,
        },
        {
          title: "已锁定",
          color: "#ccd0d1",
          status: 2,
        },
      ],
      orderType: "会员",
      options: [],
      options2: [],
      closeMode: false,
      orderMode: false,
      closeTitle: "", // 锁区提示 信息
      selected: {},
      dialogData: {
        phone: "",
      },
      dialogData2: {
        phone: "",
        username: ''
      },
      loading: false,
      ROLELIST: window.ROLELIST,
      currentCost: 0,
      changeTime: '2024-04-10',
      isChange: false,
    };
  },
  watch: {
    orderType(to) {
      console.log('orderType', to);
      if (to == '会员') {
        this.currentCost = this.totalPriceVip
      } else {
        this.currentCost = this.totalPriceCommon
      }
    },
    date(to) {
      if (moment(to).startOf().valueOf() >= moment(this.changeTime).startOf().valueOf()) {
        this.isChange = true
      } else {
        this.isChange = false;
      }
    }
  },
  mounted() {
    if (moment(this.date).startOf().valueOf() >= moment(this.changeTime).startOf().valueOf()) {
      this.isChange = true
    } else {
      this.isChange = false;
    }
    this.queryData();
  },
  methods: {
    renderTimeLine() {
      let arr = [];
      const startTime = "2023-10-01 11:00";
      // 半小时制订场上线时间：
      let total = 12
      let cnt = 1
      let unit = 'hours'
      if (this.isChange) {
        total = 23
        cnt = 30;
        unit = 'minutes'
      }
      for (let i = 0; i < total; i++) {
        arr.push(
          moment(startTime)
            .add(i * cnt, unit)
            .format("HH:mm")
        );
      }
      this.timeLine = arr;
    },
    queryData() {
      this.renderTimeLine()
      // 八个场地 时间是11点到22点 22个点
      let canlender = [];
      // 查看所有的锁场信息
      getCloseByDate(
        qs.stringify({
          date: moment(this.date).format("YYYY-MM-DD"),
        })
      ).then((closeInfo) => {
        if (closeInfo.code == 200 && closeInfo.data && closeInfo.data.length) {
          this.currentCloseInfo = JSON.parse(JSON.stringify(closeInfo.data[0]));
          this.currentCloseInfo.closeInfo = JSON.parse(
            this.currentCloseInfo.closeInfo
          );
        } else {
          this.currentCloseInfo = null;
        }
        closeInfo = closeInfo.data || [];
        closeInfo.forEach((v, i) => {
          closeInfo[i].closeInfo = JSON.parse(v.closeInfo);
          for (let data of closeInfo[i].closeInfo) {
            data.data = data.data.filter(v => v)
          }
        });
        console.log(closeInfo);
        // 查询所有有效的预定记录
        getOrderByDate(
          qs.stringify({
            date: moment(this.date).format("YYYY-MM-DD"),
            orderStatus: "1",
          })
        ).then((res) => {
          for (let r of res.data) {
            r.orderInfo = JSON.parse(r.orderInfo);
          }
          let orderList = res.data;
          for (let i = 1; i <= 8; i++) {
            const areaId = `${i}号场地`;
            // canlender
            let obj = {
              areaId,
              area: [],
            };
            let totalCnt = 11;
            if (this.isChange) {
              totalCnt = 22
            }
            for (let j = 0; j < totalCnt; j++) {
              obj.area.push({
                status: 0,
              });
            }
            canlender.push(obj);
          }
          for (let order of orderList) {
            console.log(order)
            for (let info of order.orderInfo) {
              let area = canlender.find((v) => v.areaId == info.areaId);
              for (let data of info.data) {
                let index = this.timeLine.findIndex(
                  (v) => v == data.time.slice(0, 5)
                );
                area.area[index].status = 1;
                area.area[index].orderPhone = order.phone;
                if (order.userId) {
                  area.area[index].orderUser = order.userId.username;
                }
                area.area[index].orderInfo = order;
              }
            }
          }
          console.log(closeInfo)
          console.log(this.timeLine)
          for (let item of closeInfo) {
            for (let info of item.closeInfo) {
              let area = canlender.find((v) => v.areaId == info.areaId);
              for (let data of info.data) {
                let index = this.timeLine.findIndex((v) => v == data);
                console.log(index)
                console.log(area.area)
                if (area.area[index]) {
                  area.area[index].status = 2;
                  area.area[index].orderPhone = null;
                }
              }
            }
          }
          this.areas = canlender;
          console.log(canlender)
          this.areasBack = JSON.parse(JSON.stringify(canlender));
        });
      });
    },
    // 点击锁区
    closeArea() {
      if (this.orderMode) {
        this.$message.error("当前处于订场模式，请先退出");
        return;
      }
      this.closeMode = true;
      let closeInfo = this.currentCloseInfo
        ? JSON.parse(JSON.stringify(this.currentCloseInfo)).closeInfo
        : [];
      console.log(closeInfo);
      this.selected = {};
      for (let item of closeInfo) {
        let arr = [];
        for (let time of item.data) {
          arr.push(this.timeLine.findIndex((v) => v == time));
        }
        this.selected[item.areaId] = arr;
      }
      console.log(this.selected);
    },
    // 点击订场
    orderArea() {
      this.selected = {}
      // 查看配置价格没
      getSetting().then((res) => {
        if (res.code == 200 && res.data.length && res.data[0].commonPrice) {
          if (this.closeMode) {
            this.$message.error("当前处于锁定模式，请先退出");
            return;
          }
          this.commonPrice = JSON.parse(res.data[0].commonPrice);
          if (res.data[0].priceChangeHistory) {
            this.changePrice = JSON.parse(res.data[0].priceChangeHistory);
          }

          let currentDate = moment(this.date).format("YYYY-MM-DD");
          if (
            this.changePrice &&
            this.changePrice.length &&
            this.changePrice.filter((v) => v.dates.includes(currentDate)).length
          ) {
            this.currentPrice = this.changePrice.filter((v) =>
              v.dates.includes(currentDate)
            )[0];
          } else {
            this.currentPrice = this.commonPrice;
          }
          console.log(this.currentPrice);
          this.orderMode = true;
        } else {
          this.$message.error("请先前往价格配置页面配置价格");
        }
      });
    },
    closeByDate(item) {
      console.log(this.selected, item);
      this.$confirm(
        `确定锁定${moment(this.date).format("YYYY-MM-DD")}全天的${
          item.areaId
        }吗？如已有场地有预定，请确认已联系。`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let totalCnt = 11;
        if (this.isChange) {
          totalCnt = 22
        }
        this.selected[item.areaId] = Array.from({ length: totalCnt }, (v, k) => k);
        for (let area of item.area) {
          area.status = 2;
        }
      });
    },
    cancelClose() {
      this.closeMode = false;
      this.selected = {};
      this.areas = JSON.parse(JSON.stringify(this.areasBack));
    },
    cancelOrder() {
      this.orderMode = false;
      this.selected = {};
      this.areas = JSON.parse(JSON.stringify(this.areasBack));
    },
    // 点击区域
    clickEachArea(item, index) {
      let block = item.area[index];
      if (block.status == 1) {
        if (block.orderPhone) {
          // 需要跳转到预定记录页面 快速查询这笔订单进行取消订场
          console.log(block)
          this.$confirm('该场地已被预定，是否前往取消？',
          "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "点错了",
              type: "warning",
            }
          ).then(() => {
            this.$router.push(`/order?id=${block.orderInfo._id}`)
          })
          return;
        }
        block.status = 0;
        // 取消勾选
        let idx = this.selected[item.areaId].findIndex((v) => v == index);
        this.selected[item.areaId].splice(idx, 1);
        return
      }
      if (this.closeMode) {
        if (block.status == 0) {
          block.status = 2;
          this.handleSelect(item, index);
        } else if (block.status == 1) {
          this.$confirm(
            `该时段已被【${block.orderPhone}】预定，确定还要锁定吗`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            block.status = 2;
            this.handleSelect(item, index);
          });
        } else if (block.status == 2) {
          block.status = 0;
          let idx = this.selected[item.areaId].findIndex((v) => v == index);
          this.selected[item.areaId].splice(idx, 1);
        }
      } else {
        if (this.orderMode) {
          if (block.status == 0) {
            block.status = 1;
            this.handleSelect(item, index);
          }
        }
      }
    },
    order() {
      if (this.orderType == '会员') {
        if (!this.dialogData.phone) {
          this.$message.error('请选择用户')
          return
        }
        if (this.currentCost > this.dialogData.surplus) {
          this.$message.error('余额不足')
          return
        }
        addOrder({
          ...this.paramsVip,
          orderInfo: JSON.stringify(this.paramsVip.orderInfo),
          userId: this.dialogData._id,
          phone: this.dialogData.phone,
          from: 'vip'
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success("预定成功");
            this.queryData();
            this.orderMode = false;
            this.orderDailog = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        // 对输入的手机号进行校验
        if (!this.dialogData2.phone) {
          this.$message.error('请输入手机号')
          return
        }
        const pattern = /^1[3456789]\d{9}$/;
        if (!pattern.test(this.dialogData2.phone)) {
          this.$message.error('手机号不符合规则')
          return
        }
        addOrderCommon({
          ...this.paramsCommon,
          orderInfo: JSON.stringify(this.paramsCommon.orderInfo),
          phone: this.dialogData2.phone,
          username: this.dialogData2.username,
          from: 'other',
          nickname: ''
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success("预定成功");
            this.queryData();
            this.orderMode = false;
            this.orderDailog = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      
    },
    handleClose() {
      this.orderDailog = false;
    },
    chooseUser(ev) {
      let options = JSON.parse(JSON.stringify(this.options))
      this.dialogData = options.find((v) => v.phone == ev);
      this.getSelectUser()
    },
    chooseUser2(ev) {
      let options = JSON.parse(JSON.stringify(this.options2))
      this.dialogData = options.find((v) => v.username == ev);
      this.getSelectUser()
    },
    getSelectUser() {
      getRecharge({
        userId: this.dialogData._id,
        pageNo: 1,
        pageSize: 1000
      }).then(res => {
        res.data = res.data.filter(v => {
          if (v.endTime) {
            if (moment().valueOf() > moment(v.endTime).valueOf()) {
              return false
            } else {
              return true
            }
          } else {
            console.log(moment().diff(moment(Number(v.sTime)), 'days'))
            if (moment().diff(moment(Number(v.sTime)), 'days') <= 365) {
              return true
            } else {
              return false
            }
          }
        })
        console.log(res.data)
        let surplus = res.data.map(v => v.surplus)
        console.log(surplus)
        surplus = surplus.reduce((a, b) => {
          return a + b
        }, 0)
        console.log(surplus)
        this.dialogData.surplus = surplus
        this.dialogData = {
          ...this.dialogData
        }
      })
    },
    remoteMethod: debounce(
      async function(phone) {
        if (phone !== "") {
          this.loading = true;
          this.options = await this.queryUser({
            phone,
            role: '1'
          });
          console.log(this.options)
        } else {
          this.options = [];
        }
      },
      500,
      true
    ),
    remoteMethod2: debounce(
      async function(username) {
        if (username !== "") {
          this.loading = true;
          this.options2 = await this.queryUser({
            username,
            role: '1'
          });
          console.log(this.options2);
        } else {
          this.options2 = [];
        }
      },
      500,
      true
    ),
    queryUser(params) {
      return new Promise((resolve) => {
        getUser(
          qs.stringify({
            ...params,
            pageSize: 1000,
            pageNo: 1,
          })
        ).then((res) => {
          this.loading = false;
          let rs = res.data || [];
          resolve(rs);
        });
      });
    },
    submit() {
      console.log(this.selected)
      this.dialogData = {
        phone: "",
      }
      this.orderType = "会员"
      if (Object.keys(this.selected).length == 0) {
        this.$message.error("请选择场地");
        return;
      }
      this.dialogData2 = {
        phone: '',
        username: ''
      }
      if (this.orderMode) {
        let error = ''
        let lxArr = []
        for (let key in this.selected) {
          if (this.selected[key].length) {
            let court = this.selected[key]
            court = court.sort((a, b) => a - b)
            if (court.length == 1) {
              error = '每片场地至少一小时起订'
            } else {
              // 查看是否连续
              let lastValue = court[0]
              let isLX = false;
              court.forEach((c, i) => {
                if (i > 0) {
                  if (c - court[i - 1] == 1) {
                    isLX = true
                  }
                  console.log(c, lastValue, c-lastValue)
                  if ((c - lastValue == 2)) {
                    error = '不能间隔半小时订场地'
                  } else {
                    lastValue = c
                  }
                }
              })
              lxArr.push(isLX)
            }
          }
        }
        if (lxArr.includes(false)) {
          this.$message.error('连续的一小时起订')
          return
        }
        if (error) {
          this.$message.error(error)
          return
        }
        // 提交订场
        this.dialogData = {};
        // 在这里就得计算场地费用
        let paramsVip = {
          orderDate: moment(this.date).format("YYYY-MM-DD"),
          createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          orderStatus: "1",
          orderInfo: [],
        };
        let paramsCommon = {
          orderDate: moment(this.date).format("YYYY-MM-DD"),
          createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          orderStatus: "1",
          orderInfo: [],
        };
        let totalPriceVip = 0;
        let totalPriceCommon = 0;
        for (let areaId of Object.keys(this.selected)) {
          if (this.selected[areaId].length) {
            let select = this.selected[areaId];
            select.sort((a, b) => {
              return a - b;
            });
            let objVip = {
              areaId,
              data: [],
              status: 1,
            };
            let objCommon = {
              areaId,
              data: [],
              status: 1,
            };
            for (let index of select) {
              const t = this.timeLine[index];
              let priceVip = 0;
              let priceCommon = 0;
              if (
                moment(`2022-10-01 ${t}`).valueOf() <
                moment("2022-10-01 17:00:00")
              ) {
                priceVip = Number(this.currentPrice.vipPriceLow);
                priceCommon = Number(this.currentPrice.priceLow);
              } else {
                priceVip = Number(this.currentPrice.vipPriceHigh);
                priceCommon = Number(this.currentPrice.priceHigh);
              }
              let discount = 1;
              let cnt = 60;
              if (this.isChange) {
                discount = 2
                cnt = 30
              }
              totalPriceVip += priceVip / discount;
              totalPriceCommon += priceCommon / discount;
              let time = `${t}-${moment(`2022-10-01 ${t}`)
                .add(cnt, "minute")
                .format("HH:mm")}`;
              objVip.data.push({
                time,
                price: priceVip,
              });
              objCommon.data.push({
                time,
                price: priceCommon,
              });
            }
            paramsVip.orderInfo.push(objVip);
            paramsCommon.orderInfo.push(objCommon);
          }
        }
        paramsVip.orderPrice = totalPriceVip
        paramsCommon.orderPrice = totalPriceCommon
        this.totalPriceVip = totalPriceVip;
        this.totalPriceCommon = totalPriceCommon;
        this.currentCost = totalPriceVip
        this.paramsVip = paramsVip
        this.paramsCommon = paramsCommon
        if (!paramsVip.orderInfo.length) {
          this.$message.error("请选择场地");
          return;
        }
        console.log(paramsVip, paramsCommon);
        this.orderDailog = true;
      } else {
        // 锁场
        let arr = [];
        for (let areaId of Object.keys(this.selected)) {
          if (!this.selected[areaId].length) {
            delete this.selected[areaId];
          } else {
            let obj = {
              areaId,
              data: [],
            };

            for (let index of this.selected[areaId]) {
              obj.data.push(this.timeLine[index]);
            }
            arr.push(obj);
          }
        }
        console.log(this.selected)
        if (!Object.keys(this.selected).length) {
          // 意味着这一天都不需要锁场，
          console.log(this.currentCloseInfo._id)
          if (this.currentCloseInfo._id) {
            updateClose(this.currentCloseInfo._id, {
              clear: true
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success("操作成功");
                this.queryData();
                this.closeMode = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.success("取消操作");
            this.closeMode = false;
          }
        } else {
          this.doCloseArea(arr);
        }
      }
    },
    doCloseArea(arr) {
      // 锁场
      if (!this.currentCloseInfo) {
        addClose({
          closeTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          closeDate: moment(this.date).format("YYYY-MM-DD"),
          closeInfo: JSON.stringify(arr),
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success("锁场成功");
            this.queryData();
            this.closeMode = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        updateClose(this.currentCloseInfo._id, {
          closeTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          closeInfo: JSON.stringify(arr),
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success("锁场成功");
            this.queryData();
            this.closeMode = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleSelect(item, index) {
      if (this.selected[item.areaId]) {
        if (this.selected[item.areaId].includes(index)) {
          // 取消勾选
          this.selected[item.areaId].splice(index, 1);
        } else {
          this.selected[item.areaId].push(index);
        }
      } else {
        this.selected[item.areaId] = [index];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  .content-inner {
    display: flex;
    margin: auto;
  }
  .block {
    width: 80px;
    border-radius: 4px;
    text-align: center;
    color: #756868;
    cursor: pointer;
    margin-bottom: 2px;
  }
  .block-timeline {
    width: 50px;
    border-radius: 4px;
  }

  .block-date {
    background: #fff;
    border: 1px solid #f2f2f2;
  }

  .time-line {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 50px;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  gap: 12px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mt20 {
  margin-top: 20px;
}
.legend {
  margin-top: 8px;
  .legend-color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
}
i {
  cursor: pointer;
}
.label {
  width: 80px;
  text-align: right;
  margin-right: 5px;
}
</style>
