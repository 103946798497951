<template>
  <div class="consultation">
    <h2>用户管理</h2>
    <div class="flex-center">
      <div class="flex-center">
        <div style="width: 50px; text-align: right">手机号</div>
        <el-input
          style="width: 120px"
          size="mini"
          type="text"
          v-model="filter.phone"
        ></el-input>
      </div>
      <div class="flex-center">
        <div style="width: 50px; text-align: right">姓名</div>
        <el-input
          style="width: 120px"
          size="mini"
          type="text"
          v-model="filter.username"
        ></el-input>
      </div>
      <div class="flex-center">
        <div style="width: 50px; text-align: right">角色</div>
        <el-select
          style="width: 120px"
          size="mini"
          clearable
          v-model="filter.role"
        >
          <el-option
            v-for="role of roleList"
            :key="role.key"
            :value="role.key"
            :label="role.label"
          ></el-option>
        </el-select>
      </div>
      <el-button size="mini" type="success" @click="refreshTable('init')"
        >查询</el-button
      >
      <el-button class="ml0" size="mini" type="primary" @click="addUser"
        >新增会员</el-button
      >
      <el-tooltip class="item" effect="dark" content="仅备份会员信息，查询时间较长，请耐心等待" placement="top">
        <el-button v-loading.fullscreen.lock="fullscreenLoading" class="ml0" size="mini" type="danger" @click="backup"
          >备份最新数据</el-button
        >
      </el-tooltip>
      <download-excel
        class="export-excel-wrapper"
        :data="exportTableData"
        :fields="json_fields"
        :header="title"
        :name="title + '.xls'"
      >
        <el-button @click="checkData" size="mini" type="warning">下载会员信息</el-button>
      </download-excel>
    </div>
    <div class="consultation-block">
      <div class="table" v-loading="tableLoading">
        <el-table size="mini" :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index"> </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="username" label="姓名"> </el-table-column>
          <el-table-column prop="nickname" label="小程序昵称"> </el-table-column>
          <el-table-column prop="role" label="角色" :formatter="formatRole">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="340">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.username != 'admin' && scope.row.role == '1'"
                type="primary"
                size="mini"
                @click="queryYue(scope.row)"
                >查看余额</el-button
              >
              <el-button
                v-if="scope.row.username != 'admin'"
                type="warning"
                size="mini"
                @click="updateMoney(scope.row)"
                >充值</el-button
              >
              <el-button
                v-if="scope.row.username != 'admin'"
                type="danger"
                size="mini"
                @click="deleteData(scope.row)"
                >删除</el-button
              >
              <el-button
                v-if="scope.row.username != 'admin'"
                type="success"
                size="mini"
                @click="updateUser(scope.row)"
                >修改姓名</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="currentPage"
          @current-change="refreshTable"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="modifyForm._id ? '修改' : '充值'"
      :close-on-click-modal="false"
      :visible.sync="isVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="modifyForm"
        status-icon
        :rules="rules"
        ref="modifyForm"
        label-width="100px"
      >
        <el-form-item label="金额" prop="surplus">
          <el-input-number
            size="mini"
            :min="0"
            :step="100"
            type="text"
            v-model="modifyForm.surplus"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isVisible = false">取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="btnLoading"
          @click="submitForm('modifyForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新增"
      :visible.sync="isAddVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleAddClose"
    >
      <el-form
        :model="addForm"
        status-icon
        :rules="rules2"
        size="mini"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input :min="0" type="text" v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input type="text" v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="充值金额" prop="surplus">
          <el-input-number
            :min="100"
            type="text"
            :step="100"
            v-model="addForm.surplus"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="到期时间" prop="endTime">
          <el-date-picker
            v-model="addForm.endTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddVisible = false" size="mini">取 消</el-button>
          <!-- :loading="btnLoading" -->
        <el-button
          type="primary"
          size="mini"
          @click="submitForm('addForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改姓名"
      :visible.sync="dialogVisibleUpdate"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose3">
      <el-input v-model="newUserName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUpdate = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="commitUpdate" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看余额"
      :visible.sync="dialogVisibleYue"
      width="40%"
      :before-close="handleClose4">
      <el-table size="mini" :data="surplusList" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table size="mini" :data="scope.row.history" style="width: 100%">
              <el-table-column label="消费日期" prop="date"> </el-table-column>
              <el-table-column prop="count" label="消费金额"> </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="序号" type="index" width="60"> </el-table-column>
        <el-table-column prop="surplus" label="余额" width="100"> </el-table-column>
        <el-table-column prop="count" label="充值金额" width="100"> </el-table-column>
        <el-table-column prop="endTIme" label="到期时间" :formatter="formatEndTime" width="120"> </el-table-column>
        <el-table-column prop="sTime" label="充值时间" width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.count == scope.row.surplus"
              type="danger"
              size="mini"
              @click="deleteS(scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.surplus"
              type="primary"
              size="mini"
              @click="updateS(scope.row)"
              >消费</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleYue = false" size="mini">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="消费金额"
      :visible.sync="dialogVisibleXF"
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose5">
      <div style="display: flex;gap: 20px;align-items: center;">
        <div style="width: 100px;">消费金额</div>
        <el-input size="mini" v-model="XFCount"></el-input>  
      </div> 

      <span slot="footer" class="dialog-footer">
        <el-button @click="commitXF" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addUser, getUser, deleteUser, updateUser, getRecharge, deleteRecharge, addRecharge, checkAdminPass, updateRecharge } from "../service.js";
import qs from "qs";
import moment from "moment";
export default {
  name: "home",
  mounted() {
    this.refreshTable();
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const pattern = /^1[3456789]\d{9}$/;
        if (pattern.test(value)) {
          callback();
        } else {
          callback(new Error("手机号不符合规则"));
        }
      }
    };
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableLoading: false,
      isVisible: false,
      isAddVisible: false,
      fullscreenLoading: false,
      dialogTitle: "",
      btnLoading: false,
      dialogVisibleUpdate: false,
      dialogVisibleYue: false,
      filter: {
        phone: "",
        role: "",
        username: ""
      },
      newUserName: '',
      roleList: [
        {
          key: 1,
          label: "会员",
        },
        {
          key: 2,
          label: "散客",
        },
      ],
      modifyForm: {
        surplus: null,
      },
      addForm: {
        phone: "",
        role: "1",
        username: "",
        surplus: "",
        endTime: ""
      },
      rules: {
        surplus: [{ required: true, message: "请输入金额", trigger: "blur" }],
      },
      rules2: {
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
      surplusList: [],
      dialogVisibleXF: false,
      XFCount: 0,
      title: "会员数据备份",
      exportTableData: [],
      json_fields: {
        姓名: "username",
        昵称: "nickname",
        手机号: "phone",
        注册时间: "createTime",
        充值记录: "history",
        累计充值: "total",
        可用余额: "surplus",
      },
    };
  },
  methods: {
    handleClose() {
      this.isVisible = false;
    },
    handleClose5() {
      this.dialogVisibleXF = false;
    },
    handleAddClose() {
      this.isAddVisible = false;
    },
    submitForm(formName) {
      this.btnLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == "addForm") {
            let params = JSON.parse(JSON.stringify(this.addForm))
            let surplus = {
              surplus: params.surplus,
              sTime: moment().format('YYYY-MM-DD')
            }
            delete params.surplus
            params = {
              ...params,
              nickname: '',
              ...surplus,
              updator: JSON.parse(localStorage.getItem("userInfo")).username,
            }
            if (params.endTime) {
              params.endTime = moment(params.endTime).format('YYYY-MM-DD')
            }
            addUser(params).then((res) => {
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.isAddVisible = false;
                this.refreshTable();
              } else {
                this.$message.error(res.msg[0].msg);
              }
              this.btnLoading = false;
            });
          } else {
            if (this.modifyForm.surplus == 0) {
              this.$message.info("请输入金额");
              return;
            }
            // 新增recharge
            addRecharge({
              userId: this.currentRow._id,
              count: this.modifyForm.surplus,
              surplus: this.modifyForm.surplus,
              sTime: new Date().getTime(),
              opInfo: `【${JSON.parse(localStorage.getItem("userInfo")).username}】给【${this.currentRow.phone}】充值${this.modifyForm.surplus}`
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('充值成功')
                this.isVisible = false;
                this.btnLoading = false;
                this.refreshTable()
              }
            })
          }
        }
      });
    },
    refreshTable(init = false) {
      if (init == "init") {
        this.currentPage = 1;
      }
      let params = {
        ...this.filter,
        pageSize: this.pageSize,
        pageNo: this.currentPage,
      };
      getUser(qs.stringify(params)).then((res) => {
        this.tableData = res.code === 200 ? res.data : [];
        this.total = res.count;
      });
    },
    formatRole(row) {
      switch (row.role) {
        case 0:
        case "0":
          return "管理员";
        case 1:
        case "1":
          return "会员";
        case 2:
        case "2":
          return "散客";
        default:
          return "散客";
      }
    },
    handleClose3() {
      this.dialogVisibleUpdate = false;
    },
    deleteData(row) {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.$confirm("此操作将永久删除该用户,请确认该用户是否存在进行中的订单,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteUser(row._id).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.refreshTable();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      })
    },
    updateMoney(row) {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.currentRow = row;
        this.btnLoading = false;
        this.modifyForm.surplus = 0
        this.isVisible = true;
        this.$nextTick(() => {
          this.$refs["modifyForm"].resetFields();
        });
      })
    },
    addUser() {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.addForm = {
          phone: "",
          role: "1",
          username: "",
          surplus: "",
          endTime: ""
        }
        this.isAddVisible = true;
        this.$nextTick(() => {
          this.$refs["addForm"].resetFields();
        });
      })
    },
    modifyS(row) {
      this.btnLoading = false;
      this.currentRow = row
      this.modifyForm.surplus = row.surplus
      this.isVisible = true;
    },
    deleteS(row) {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.$confirm('此操作将永久删除该记录，请确保没有进行中的订单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let opInfo = `【${JSON.parse(localStorage.getItem("userInfo")).username}】删除了【${this.currentRow.phone}】充值记录${row.surplus}`
          deleteRecharge({
            ...row,
            opInfo
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.dialogVisibleYue = false;
              this.refreshTable();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
      })
    },
    updateS(row) {
      this.reRow = row
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        // 消费
        this.XFCount = '';
        this.dialogVisibleXF = true;
      })
    },
    updateUser(row) {
      this.newUserName = row.username
      this.dialogVisibleUpdate = true
      this.currentRow = row
    },
    commitUpdate() {
      if (!this.newUserName) {
        this.$message.error('请输入姓名')
        return
      }
      updateUser(this.currentRow._id, {
        username: this.newUserName
      }).then(res => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.refreshTable();
          this.dialogVisibleUpdate = false;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    handleClose4() {
      this.dialogVisibleYue = false;
    },
    queryYue(row) {
      this.currentRow = row
      getRecharge({
        userId: row._id,
        pageNo: 1,
        pageSize: 1000
      }).then(res => {
        res.data.forEach((v, i) => {
          res.data[i].sTime = moment(Number(v.sTime)).format('YYYY-MM-DD HH:mm:ss')
        })
        this.surplusList = res.data
        this.dialogVisibleYue = true;
      })
    },
    formatEndTime(item) {
      if (item.endTime) {
        return item.endTime
      } else {
        return moment(item.sTime).add(365, 'days').format('YYYY-MM-DD')
      }
    },
    commitXF() {
      if (!this.XFCount) {
        this.$message.error('请输入金额')
        return
      }
      if (this.reRow.surplus < Number(this.XFCount)) {
        this.$message.error('余额不足')
        return
      }
      let his = this.reRow.history ? JSON.parse(JSON.stringify(this.reRow.history)) : []
      his = his.concat([
        {
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
          count: this.XFCount
        }
      ])
      updateRecharge(this.reRow._id, {
        surplus: this.reRow.surplus-Number(this.XFCount),
        history: his
      }).then(res => {
        if (res.code == 200) {
          this.dialogVisibleXF = false;
          this.$message.success('操作成功')
          this.queryYue(this.currentRow)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    checkData() {
      if (!this.exportTableData.length) {
        this.$message.error('请先点击左侧【备份最新数据】按钮')
      }
    },
    backup() {
      this.exportTableData = []
      this.fullscreenLoading = true
      // 获取会员数量
      let params = {
        role: 1,
        pageSize: 1,
        pageNo: 1,
      };
      getUser(qs.stringify(params)).then((res) => {
        getUser(qs.stringify({
          role: 1,
          pageSize: res.count,
          pageNo: 1,
        })).then(async (vips) => {
          for (let i = 0;i < res.count;i++) {
            const user = vips.data[i]
            let yues = await getRecharge({
              userId: user._id,
              pageNo: 1,
              pageSize: 1000
            })
            yues = yues.data;
            let history = []
            let surplus = 0
            let total = 0
            for (let y of yues) {
              total += y.count
              let diff = moment().diff(moment(Number(y.sTime)), 'day')
              if (diff > 365) {
                history.push(
                  `充值时间：${moment(Number(y.sTime)).format('YYYY-MM-DD HH:mm:ss')};充值金额：${y.count};余额：${y.surplus};已过期`
                )
              } else {
                surplus += y.surplus
                history.push(
                  `充值时间：${moment(Number(y.sTime)).format('YYYY-MM-DD HH:mm:ss')};充值金额：${y.count};余额：${y.surplus}`
                )
              }
            }
            this.exportTableData.push({
              ...user,
              surplus,
              total,
              createTime: moment(new Date(user.createTime)).format('YYYY-MM-DD HH:mm:ss'),
              history: history.join('\n')
            })
          }
          this.fullscreenLoading = false
          this.$message.success('备份完成，可下载')
        })
      });
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.consultation-block {
  width: 90%;
  margin-left: 5%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.edit {
  color: #409EFF;
  cursor: pointer;
}
.delete {
  color: #F56C6C;
  cursor: pointer;
}
</style>
