<template>
  <div class="setting">
    <h2>通用价格配置(每小时)</h2>
    <el-form
      :model="form"
      status-icon
      size="mini"
      ref="form"
      label-width="160px"
      style="margin-top: 30px;"
    >
      <el-form-item label="会员价（17点前）" prop="vipPriceLow">
        <el-input type="text" v-model="form.vipPriceLow"></el-input>
      </el-form-item>
      <el-form-item label="会员价（17点后）" prop="vipPriceHigh">
        <el-input type="text" v-model="form.vipPriceHigh"></el-input>
      </el-form-item>
      <el-form-item label="非会员价（17点前）" prop="priceLow">
        <el-input type="text" v-model="form.priceLow"></el-input>
      </el-form-item>
      <el-form-item label="非会员价（17点后）" prop="priceHigh">
        <el-input type="text" v-model="form.priceHigh"></el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;">
      <el-button style="margin: 20px auto;" size="mini" type="primary" @click="saveForm">保存</el-button>
    </div>
    <h2>特定时间价格配置(每小时)</h2>
    <el-button style="margin: 20px auto;" size="mini" type="primary" @click="addRow">添加</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="dates"
        label="调价日期"
      >
      </el-table-column>
      <el-table-column label="价格">
        <template slot-scope="scope">
          <el-form
            :model="scope.row"
            status-icon
            size="mini"
            ref="form"
            label-width="160px"
            style="margin-top: 30px;"
          >
            <el-form-item label="会员价（17点前）" prop="vipPriceLow">
              <el-input disabled type="text" v-model="scope.row.vipPriceLow"></el-input>
            </el-form-item>
            <el-form-item label="会员价（17点后）" prop="vipPriceHigh">
              <el-input disabled type="text" v-model="scope.row.vipPriceHigh"></el-input>
            </el-form-item>
            <el-form-item label="非会员价（17点前）" prop="priceLow">
              <el-input disabled type="text" v-model="scope.row.priceLow"></el-input>
            </el-form-item>
            <el-form-item label="非会员价（17点后）" prop="priceHigh">
              <el-input disabled type="text" v-model="scope.row.priceHigh"></el-input>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.orderStatus == '1'"
            type="warning"
            @click="handleCancel(scope.row)"
            >取消</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="特定时间价格配置" :visible.sync="isVisible">
      <el-form
        :model="formDialog"
        status-icon
        size="mini"
        ref="form"
        label-width="160px"
        style="margin-top: 30px;"
      >
        <el-form-item label="日期（可多选）" prop="dates">
          <el-date-picker
          type="dates"
          v-model="formDialog.dates"
          placeholder="选择一个或多个日期">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="会员价（17点前）" prop="vipPriceLow">
          <el-input type="text" v-model="formDialog.vipPriceLow"></el-input>
        </el-form-item>
        <el-form-item label="会员价（17点后）" prop="vipPriceHigh">
          <el-input type="text" v-model="formDialog.vipPriceHigh"></el-input>
        </el-form-item>
        <el-form-item label="非会员价（17点前）" prop="priceLow">
          <el-input type="text" v-model="formDialog.priceLow"></el-input>
        </el-form-item>
        <el-form-item label="非会员价（17点后）" prop="priceHigh">
          <el-input type="text" v-model="formDialog.priceHigh"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addChangePrice">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSetting, updateSetting } from "../service.js";
import moment from 'moment';
export default {
  data() {
    return {
      form: {
        vipPriceLow: '',
        vipPriceHigh: '',
        priceLow: '',
        priceHigh: ''
      },
      formDialog: {
        vipPriceLow: '',
        vipPriceHigh: '',
        priceLow: '',
        priceHigh: '',
        dates: []
      },
      isVisible: false,
      tableData: [],
      dates: []
    };
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    saveForm() {
      if (!this.form.vipPriceLow || !this.form.vipPriceHigh || !this.form.priceLow || !this.form.priceHigh) {
        this.$message.error('请配置价格')
        return
      }
      updateSetting({
        commonPrice: JSON.stringify(this.form)
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success('保存成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    addRow() {
      this.isVisible = true
    },
    getSetting() {
      getSetting().then(res => {
        console.log(res)
        if (res.code == 200 && res.data && res.data.length && res.data[0].commonPrice) {
          this.form = JSON.parse(res.data[0].commonPrice)
        }
        if (res.code == 200 && res.data && res.data.length && res.data[0].priceChangeHistory) {
          this.tableData = JSON.parse(res.data[0].priceChangeHistory)
        }
      })
    },
    deleteImg(index) {
      this.swipers.pics.splice(index, 1);
    },
    handleDelete(item) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(item)
        let index = this.tableData.findIndex(v => v== item)
        console.log(index)
        this.tableData.splice(index, 1)
        updateSetting({
          priceChangeHistory: JSON.stringify(this.tableData)
        }).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
      
    },
    addChangePrice() {
      if (this.formDialog.dates.length == 0 || !this.formDialog.vipPriceLow || !this.formDialog.vipPriceHigh || !this.formDialog.priceLow || !this.formDialog.priceHigh) {
        this.$message.error('配置不能为空')
        return
      }
      // 处理日期
      let dates = []
      for (let date of this.formDialog.dates) {
        dates.push(moment(date).format('YYYY-MM-DD'))
      }
      this.tableData.push({
        ...this.formDialog,
        dates: dates.join(',')
      })
      updateSetting({
        priceChangeHistory: JSON.stringify(this.tableData)
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.isVisible = false;
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
};
</script>

<style scoped>


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px dashed gray;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.img-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-icon-circle-close {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: red;
}
</style>
