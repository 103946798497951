/*
 * @Author: wangyifei 844650674@qq.com
 * @Date: 2022-11-27 10:22:27
 * @LastEditors: wangyifei 844650674@qq.com
 * @LastEditTime: 2022-11-27 13:45:55
 * @FilePath: \management_system_template\src\service.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function ajaxRequest(url, method, params) {
  return new Promise((resolve, reject) => {
    let data;
    if (method === "POST" || method === "PUT") {
      data = JSON.stringify(params);
    } else {
      data = null;
    }
    $.ajax({
      type: method,
      url,
      data,
      contentType: "application/json;charset=utf-8",
      beforeSend: function(request) {
        if (localStorage.getItem("userInfo")) {
          request.setRequestHeader(
            "Authorization",
            JSON.parse(localStorage.getItem("userInfo")).token
          );
        }
      },
      success: (res) => {
        resolve(res);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log(jqXHR.status == 401)
        if (jqXHR.status == 401) {
          alert('登录信息过期，请重新登陆')
        }
        reject(textStatus);
      },
    });
  });
}

// 登录
export const login = (params) => {
  return ajaxRequest("/users/login", "POST", params);
};

// 新建用户
export const addUser = (params) => {
  return ajaxRequest("/users", "POST", params);
};

// 获取用户列表
export const getUser = (params) => {
  return ajaxRequest(`/users?${params}`, "GET", {});
};

// getUserInfo
export const getUserInfo = (id) => {
  return ajaxRequest(`/user/${id}`, "GET");
};

export const hasAdmin = (params) => {
  return ajaxRequest(`/users/hasAdmin`, "POST", params);
}

// 删除用户
export const deleteUser = (id) => {
  return ajaxRequest(`/user/${id}`, "DELETE");
}

// 编辑用户
export const updateUser = (id, params) => {
  return ajaxRequest(`/user/${id}`, "POST", params);
};
export const updateUserP = (id, params) => {
  return ajaxRequest(`/user/pass/${id}`, "POST", params);
};
// 获取模板信息
export const getSetting = () => {
  return ajaxRequest(`/setting`, "GET");
}
// 编辑用户
export const updateSetting = (params) => {
  return ajaxRequest(`/setting`, "POST", params);
};

export const getRecord = (params) => {
  return ajaxRequest(`/record?${params}`, "GET");
}
export const getOrder = (params) => {
  return ajaxRequest(`/order?${params}`, "GET");
}
export const getOrderByDate = (params) => {
  return ajaxRequest(`/order/all?${params}`, "GET", {});
}
export const getCloseByDate = (params) => {
  return ajaxRequest(`/close/all?${params}`, "GET", {});
}
export const addOrder = (params) => {
  return ajaxRequest("/order", "POST", params);
};

export const addClose = (params) => {
  return ajaxRequest("/close", "POST", params);
};
export const deleteOrder = (id) => {
  return ajaxRequest(`/order/${id}`, "DELETE");
}

export const updateOrder = (id, params) => {
  return ajaxRequest(`/order/${id}`, "POST", params);
};

export const updateClose = (id, params) => {
  return ajaxRequest(`/close/${id}`, "POST", params);
};

export const addOrderCommon = (params) => {
  return ajaxRequest("/order/common", "POST", params);
};

export const gerWxOrderInfo = (id) => {
  return ajaxRequest(`/wx/getOrder/${id}`, "GET");
}

export const submitRefund = (params) => {
  return ajaxRequest("/wx/refund", "POST", params);
};

export const getRecharge = (params) => {
  return ajaxRequest("/recharge/list", "POST", params);
};

export const deleteRecharge = (params) => {
  return ajaxRequest(`/recharge/${params._id}`, "DELETE", params);
};
export const addRecharge = (params) => {
  return ajaxRequest(`/recharge`, "POST", params);
};

export const updateRecharge = (id, params) => {
  return ajaxRequest(`/recharge/${id}`, "POST", params);
};
export const addActivity = (params) => {
  return ajaxRequest(`/activity`, "POST", params);
};

export const deleteActivity = (id) => {
  return ajaxRequest(`/activity/${id}`, "DELETE");
}

export const updateActivity = (id, params) => {
  return ajaxRequest(`/activity/${id}`, "POST", params);
};

export const getActivity = (params) => {
  return ajaxRequest(`/activity?${params}`, "GET", {});
};

export const checkAdminPass = (params) => {
  console.log(params)
  return ajaxRequest(`/user/pass/check`, "POST", params);
};


