<template>
  <div class="setting">
    <h2>场馆配置</h2>
    <el-button type="primary" size="mini" @click="setSwiper">配置轮播图</el-button>
    <el-carousel indicator-position="outside" :height="'400px'" style="margin-top: 20px;">
      <el-carousel-item v-for="item in form.swipers" :key="item">
        <img
          style="width: 100%; height: 100%;"
          :src="item" 
         >
        </img>
      </el-carousel-item>
    </el-carousel>
    <el-form
      :model="form"
      status-icon
      size="mini"
      ref="form"
      label-width="100px"
      style="margin-top: 30px;"
    >
      <el-form-item label="地址" prop="phone">
        <el-input :min="0" type="text" v-model="form.location"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input :min="0" type="text" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="营业时间" prop="openTime">
        <el-input :min="0" type="text" v-model="form.openTime"></el-input>
      </el-form-item>
      <el-form-item label="滚动通知" prop="broadcast">
        <el-input :min="0" type="text" v-model="form.broadcast"></el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;">
      <el-button style="margin: 20px auto;" size="mini" type="primary" @click="saveForm">保存</el-button>
    </div>

    <el-dialog :close-on-click-modal="false" title="上传轮播图" :visible.sync="isVisible">
      <div class="img-blocks">
        <div
          v-for="(img, index) in form.swipers"
          :key="index"
          style="position: relative"
        >
          <img :src="img" style="width: 80px" />
          <i @click="deleteImg(index)" class="el-icon-circle-close"></i>
        </div>
      </div>
      <el-upload
        class="avatar-uploader"
        :action="'/upload'"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSetting, updateSetting } from "../service.js";

export default {
  data() {
    return {
      form: {
        swipers: [],
        location: '',
        phone: '',
        openTime: '',
        broadcast: ''
      },
      isVisible: false,
    };
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    handleAvatarSuccess(info) {
      console.log(info)
      this.form.swipers.push(info.url);
    },
    saveForm() {
      let form = JSON.parse(JSON.stringify(this.form))
      form.swipers = form.swipers.join(',')
      console.log(form)
      updateSetting(form).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success('保存成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    setSwiper() {
      this.isVisible = true
    },
    getSetting() {
      getSetting().then(res => {
        console.log(res)
        if (res.code == 200 && res.data && res.data.length) {
          this.form = {
            ...res.data[0],
            swipers: res.data[0].swipers ? res.data[0].swipers.split(',') : []
          }
        }
      })
    },
    deleteImg(index) {
      this.form.swipers.splice(index, 1);
    },
  },
};
</script>

<style scoped>


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px dashed gray;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.img-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-icon-circle-close {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: red;
}
</style>
