<template>
  <div class="setting">
    <h2>预定记录</h2>
    <div class="filter flex-between mt20">
      <div class="flex-center">
        <div class="flex-center">
          <div class="label">预定日期</div>
          <el-date-picker
            v-model="filter.orderDate"
            size="mini"
            :clearable="true"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="flex-center">
          <div class="label">预定人电话</div>
          <el-input size="mini" v-model="filter.phone"></el-input>
        </div>
        <div class="flex-center">
          <div class="label">姓名</div>
          <el-select
            clearable
            v-model="filter.userId"
            filterable
            size="mini"
            placeholder="请输入手机号"
          >
            <el-option
              v-for="item in allUser"
              :key="item._id"
              :label="item.username"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="getOrder(true)"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          style="margin-left: 0"
          @click="getOrder(false)"
          >刷新</el-button
        >
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="phone" label="预定电话" :width="180">
      </el-table-column>
      <el-table-column prop="userId.username" label="姓名" :width="180" :formatter="formatName">
      </el-table-column>
      <el-table-column
        prop="createTime"
        :formatter="formatTime"
        label="创建时间"
        :width="180"
      >
      </el-table-column>
      <el-table-column prop="orderDate" label="预定日期" :width="180">
      </el-table-column>
      <el-table-column label="预定详情" :width="380">
        <template slot-scope="scope">
          <div
            class="text-center"
            v-for="(item, index) of scope.row.orderInfo"
            :key="index"
          >
            {{ item.areaId }}:{{ item.data.map((v) => v.time).join(";") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="orderPrice" label="订单金额（元）" :width="120">
      </el-table-column>
      <el-table-column
        prop="orderStatus"
        :formatter="formatStatus"
        label="订单状态"
        :width="180"
      >
      </el-table-column>
      <el-table-column
        prop="from"
        :formatter="formatFrom"
        label="订单来源"
        :width="180"
      >
      </el-table-column>
      <el-table-column width="120" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" v-if="scope.row.orderStatus !== '1' && scope.row.orderStatus !== '-1'" type="danger" @click="handleDelete(scope.row)"
            >删除记录</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.orderStatus == '1'"
            type="warning"
            @click="handleCancel(scope.row)"
            >取消订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="getOrder(false)"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :close-on-click-modal="false" title="订单详情" :visible.sync="refundVislble">
      <div v-if="wxOrderDetail.transaction_id" class="order-detail">
        <div class="flex-center">
          <div>金额：</div>
          <div>{{ wxOrderDetail.amount.total / 100 }}元</div>
        </div>
        <div class="flex-center">
          <div>交易单号：</div>
          <div>{{ wxOrderDetail.transaction_id }}</div>
        </div>
        <div class="flex-center">
          <div>交易时间：</div>
          <div>{{ wxOrderDetail.success_time }}</div>
        </div>
        <div class="flex-center">
          <div>商户退款单号：</div>
          {{ out_refund_no }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="confirmRefund">确认退款</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrder, deleteOrder, updateOrder, gerWxOrderInfo, submitRefund, checkAdminPass, getUser } from "../service.js";
import moment from "moment";
import qs from "qs";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      filter: {
        orderDate: '',
        phone: '',
        userId: ''
      },
      transaction_id: '',
      out_refund_no: '',
      refundVislble: false,
      wxOrderDetail: {},
      allUser: []
    };
  },
  mounted() {
    let params = {
      pageSize: 10000,
      pageNo: 1,
    }
    getUser(qs.stringify(params)).then((res) => {
      this.allUser = res.data
      console.log(this.allUser)
    });
    console.log(this.$route.query)
    if (this.$route.query.id) {
      this.getOrderById();
    } else {
      this.getOrder();
    }
    
  },
  methods: {
    getOrder(init = false) {
      console.log(init)
      if (init) {
        this.currentPage = 1;
      }
      let params = {
        ...this.filter,
        pageSize: this.pageSize,
        pageNo: this.currentPage,
      }
      if (params.orderDate) {
        params.orderDate = moment(params.orderDate).format('YYYY-MM-DD')
      }
      getOrder(qs.stringify(params)).then((res) => {
        this.tableData = res.data || [];
        for (let item of this.tableData) {
          item.orderInfo = item.orderInfo ? JSON.parse(item.orderInfo) : [];
        }
        this.total = res.count;
        console.log(this.tableData);
      });
    },
    getOrderById() {
      getOrder(qs.stringify({
        id: this.$route.query.id
      })).then((res) => {
        this.tableData = res.data || [];
        for (let item of this.tableData) {
          item.orderInfo = item.orderInfo ? JSON.parse(item.orderInfo) : [];
        }
        this.total = res.count;
        console.log(this.tableData);
      });
    },
    formatTime(item) {
      if (item) {
        const time = new Date(item.createTime);
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    formatName(item) {
      console.log(item)
      return item.userId.username || '小程序用户'
    },
    formatStatus(item) {
      if (item.orderStatus == '1') {
        return '正常'
      } else if (item.orderStatus == '0') {
        return '已取消'
      } else if (item.orderStatus == '-1') {
        return '待付款'
      } else if (item.orderStatus == '-2') {
        return '已退款'
      } else {
        return '其他'
      }
    },
    formatFrom(item) {
      if (item.from == 'wx') {
        return '微信支付'
      } else if (item.from == 'vip') {
        return '储值用户'
      } else {
        return '其他'
      }
    },
    handleDelete(row) {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteOrder(row._id).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.getOrder();
            }
          });
        });
      });
    },
    handleCancel(row) {
      this.$prompt('请输入密码', '二次密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async (password) => {
        // 密码确认
        const rs = await checkAdminPass({
          password: password.value
        })
        if (rs.code == 500) {
          this.$message.error(rs.msg)
          return;
        }
        this.currentRow = row
        if (row.from == 'wx' && row.wxOrderId) {
          // 微信在线支付
          this.$confirm("该订单为微信在线支付订单, 是否办理退款?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const wxOrderId = JSON.parse(row.wxOrderId)
            this.wxOrderDetail = {}
            this.transaction_id = wxOrderId.transaction_id
            this.out_refund_no = wxOrderId.out_trade_no
            this.refundVislble = true;
            this.$nextTick(() => {
              this.queryTid()
            })
          });
          return
        }
        // 判断该订单是否已经过去
        // 找出订单中最早的时间
        const orderInfo = row.orderInfo
        let ymd = row.orderDate
        let minTime = ''
        for (let order of orderInfo) {
          for (let data of order.data) {
            let time = `${ymd} ${data.time.split('-')[0]}`
            if (minTime) {
              if (moment(minTime).valueOf() > moment(time).valueOf()) {
                minTime = time
              }
            } else {
              minTime = time
            }
          }
        }
        console.log(minTime)
        if (minTime && moment().valueOf() > moment(minTime).valueOf()) {
          this.$confirm("当前订单存在进行中或者已结束的场地，确认取消订单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            updateOrder(row._id, { ...row, orderStatus: "0" }).then((res) => {
              if (res.code == 200) {
                this.$message.success("操作成功");
                this.getOrder();
                this.$router.push('/order')
              } else {
                this.$message.error(res.msg)
              }
            });
          });
        } else {
          updateOrder(row._id, { ...row, orderStatus: "0" }).then((res) => {
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.getOrder();
              this.$router.push('/order')
            } else {
              this.$message.error(res.msg)
            }
          });
        }
      })
    },
    confirmRefund() {
      if (this.wxOrderDetail.transaction_id && this.out_refund_no) {
        // 处理退款
        submitRefund({
          ...this.wxOrderDetail,
          out_refund_no: this.out_refund_no
        }).then(res => {
          if (res.code == 200) {
            // 执行本系统的取消订单接口
            updateOrder(this.currentRow._id, { ...this.currentRow, orderStatus: "0", from: 'wx' }).then((res) => {
              if (res.code == 200) {
                this.$message.success("退款成功");
                this.getOrder();
                this.$router.push('/order')
                this.refundVislble = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    queryTid() {
      if (!this.transaction_id) {
        this.$message.error('无订单号，订单异常')
        return
      }
      gerWxOrderInfo(this.transaction_id).then(res => {
        console.log(res)
        if (res.code == 200) {
          const data = JSON.parse(res.data)
          console.log(data)
          this.wxOrderDetail = data;
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
.label {
  min-width: 85px;
  color: #666;
  text-align: right;
}
.order-detail {
  padding: 12px;

  &>div {
    margin-bottom: 10px;
  }
}
</style>
