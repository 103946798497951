<template>
  <div>
    <div class="header">
      <div class="title">系统管理

    <i v-if="isOpen" class="el-icon-s-fold coll" @click="isOpen = !isOpen"></i>
    <i v-if="!isOpen" class="el-icon-s-unfold coll" @click="isOpen = !isOpen"></i>
      </div>
      <el-dropdown trigger="click" @command="dropdown">
        <div class="account">
          <span>{{ userName }}</span>
          <img width="40" height="40" src="../assets/img/people.png">
        </div>
        <el-dropdown-menu slot="dropdown" class="header-dropdown">
          <el-dropdown-item command="logout">退出</el-dropdown-item>
          <el-dropdown-item command="psd">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <current-tenant style="margin-right:20px;"></current-tenant> -->
    </div>
    <sidebar v-if="isOpen" class="sidebar-container"></sidebar>
    <div class="main-container" ref="main">
      <router-view />
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="comfirmM">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sidebar from './Sidebar';
import {
  updateUserP
} from '../service'
export default {
  components: {
    Sidebar
  },
  data() {
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      userName: '',
      isOpen: true,
      ruleForm: {
          pass: '',
          checkPass: '',
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        },
      dialogVisible: false
    };
  },
  watch: {
    isOpen(to) {
      if (to) {
        this.$refs.main.style.width = `80%`
        this.$refs.main.style.marginLeft = `210px`
      } else {
        this.$refs.main.style.width = '95%'
        this.$refs.main.style.marginLeft = `0%`
      }
    }
  },
  mounted() {
    if (!localStorage.getItem('userInfo')) {
      this.$router.push('/login')
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    dropdown(command) {
      if (command === 'logout') {
        this.$confirm('确定退出吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',          
        }).then(() => {
          Cookies.remove('token');
          Cookies.remove('manage_user');
          this.$router.push({
            path: '/login'
          });
        });
      } else if (command === 'psd') {
        this.dialogVisible = true
      }
    },
    comfirmM() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          updateUserP(userInfo._id, {
            password: this.ruleForm.pass
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功,请重新登录')
              localStorage.removeItem('userInfo')
              this.$router.push('/login')
            } else {
              this.$message.error(res.msg)
            }
          }) 
        }
      });
    }
  }
}
</script>
<style>
.header {
  height: 50px;
  width: 100%;
  background-color: #ef6c3c;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 500;
  display: flex;
  justify-content: space-between;
}
.el-dropdown {
  align-self: center;
  margin-right: 20px;
}
.title {
  margin-left: 20px;
  align-self: center;
  color: #ffffff;
  font-size: 24px;
}
.account {
  align-self: center;
  display: flex;
  cursor: pointer;
}
.account span {
  align-self: center;
  font-size: 20px;
  color: #cfcfcf;
  margin-right: 5px;  
}
.coll {
  color: #fff;
  font-size: 16px;
}
</style>