<template>
  <div class="consultation">
    <h2>活动管理</h2>
    <div class="flex-center">
      <el-button class="ml0" size="mini" type="primary" @click="addUser"
        >新增</el-button
      >
    </div>
    <div class="consultation-block">
      <div class="table">
        <el-table size="mini" :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index"> </el-table-column>
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="createTime" label="发布时间"> </el-table-column>
          <el-table-column prop="updateTime" label="编辑时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="340">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="modifyRow(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteData(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="currentPage"
          @current-change="refreshTable"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="addForm._id ? '修改' : '新增'"
      :visible.sync="isAddVisible"
      :close-on-click-modal="false"
      width="50%"
      :before-close="handleAddClose"
    >
      <el-form
        :model="addForm"
        status-icon
        :rules="rules"
        size="mini"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="标题" prop="title">
          <el-input :min="0" type="text" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <vue-editor v-model="addForm.content"/>
        </el-form-item>
        <el-form-item label="图片" prop="imgs">
          <div class="img-blocks">
            <div
              v-for="(img, index) in addForm.imgs"
              :key="index"
              style="position: relative"
            >
              <img :src="img.url" style="width: 80px" />
              <i @click="deleteImg(index)" class="el-icon-circle-close"></i>
            </div>
          </div>
          <el-upload
            class="avatar-uploader"
            :action="'/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddVisible = false" size="mini">取 消</el-button>
          <!-- :loading="btnLoading" -->
        <el-button
          type="primary"
          size="mini"
          @click="submitForm('addForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getActivity, deleteActivity, addActivity, updateActivity } from "../service.js";
import qs from "qs";
import moment from "moment";
import { VueEditor } from 'vue2-editor'
export default {
  name: "ItemEdit",
  components: { VueEditor },
  mounted() {
    this.refreshTable();
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const pattern = /^1[3456789]\d{9}$/;
        if (pattern.test(value)) {
          callback();
        } else {
          callback(new Error("手机号不符合规则"));
        }
      }
    };
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isAddVisible: false,
      btnLoading: false,
      addForm: {
        title: "",
        content: "",
        imgs: [],
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        imgs: [{ required: true, message: "请上传图片", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleAvatarSuccess(info) {
      this.addForm.imgs.push({
        url: info.url
      });
    },
    handleAddClose() {
      this.isAddVisible = false;
    },
    submitForm(formName) {
      this.btnLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm._id) {
            updateActivity(this.addForm._id, {
              ...this.addForm,
              updateTime: moment().format('YYYY-MM-DD HH:mm:ss')
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('编辑成功')
                this.isAddVisible = false;
                this.refreshTable()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            addActivity({
              ...this.addForm,
              createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
              updateTime: moment().format('YYYY-MM-DD HH:mm:ss')
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('新增成功')
                this.isAddVisible = false;
                this.refreshTable()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      });
    },
    refreshTable(init = false) {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
      };
      getActivity(qs.stringify(params)).then((res) => {
        this.tableData = res.code === 200 ? res.data : [];
        this.total = res.count;
      });
    },
    deleteData(row) {
      this.$confirm("此操作将永久删除该用户,请确认该用户是否存在进行中的订单,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteActivity(row._id).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.refreshTable();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addUser() {
      this.isAddVisible = true;
      this.$nextTick(() => {
        this.addForm = {
          title: "",
          content: "",
          imgs: [],
        }
      });
    },
    modifyRow(row) {
      this.addForm = JSON.parse(JSON.stringify(row))
      this.isAddVisible = true
    },
    deleteImg(index) {
      this.addForm.imgs.splice(index, 1);
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.consultation-block {
  width: 90%;
  margin-left: 5%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.edit {
  color: #409EFF;
  cursor: pointer;
}
.delete {
  color: #F56C6C;
  cursor: pointer;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px dashed gray;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.img-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-icon-circle-close {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: red;
}
</style>
