// 防抖
export const debounce = (fn, delay) => {
	var time = null
	return function() {
		let context = this;//记录一下this指向
		let args = arguments;
		//清除定时任务
		if (time) clearTimeout(time);
		time = setTimeout(function() {
			time = null;
			fn.apply(context, args)
		}, delay)
	}
}

// 节流
export const throttle=(fn, delay) => {
	// 时间戳
	var timeTwo = 0 //new Date();
	// 定时器
	var timeThree = null;
	return function() {
		let context = this;
		let args = arguments;
		var now = new Date()

		// 结合 ps:最后一次触发在固定频率内会在延迟后触发
		var wait = delay - (now - timeTwo)
		clearTimeout(timeThree)
		if (wait <= 0) {
			fn.apply(context, args);
			timeTwo = new Date();
		} else {
			timeThree = setTimeout(function() {
				fn.apply(context, args);
			}, delay)
		}
	}
}
