<template>
  <div class="setting">
    <h2>操作记录</h2>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="recordInfo"
        label="操作记录">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        :formatter="formatTime"
        label="操作时间"
        :width="180">
      </el-table-column>
    </el-table>
    <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page.sync="currentPage"
      @current-change="getRecord"
      :page-size="pageSize"
      :total="total"
    >
    </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRecord } from "../service.js";
import moment from 'moment'
import qs from 'qs'
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    };
  },
  mounted() {
    this.getRecord()
  },
  methods: {
    getRecord() {
      getRecord(qs.stringify({
        pageSize: this.pageSzie,
        pageNo: this.currentPage
      })).then(res => {
        this.tableData = res.data || []
        this.total = res.count
        console.log(res)
      })
    },
    formatTime(item) {
      if (item) {
        const time = new Date(Number(item.updateTime))
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
};
</script>

<style scoped>

</style>
