<!--
 * @Author: wangyifei 844650674@qq.com
 * @Date: 2022-11-27 10:22:28
 * @LastEditors: wangyifei 844650674@qq.com
 * @LastEditTime: 2022-11-27 11:30:39
 * @FilePath: \management_system_template\src\layout\Sidebar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-scrollbar class="menu-wrapper">
      <el-menu
        :default-active="currentIndex"
        class="toolkit-menu"
        background-color="#61605E"
        text-color="#fff"
        router
      >
        <template>
          <el-menu-item-group>
            <el-menu-item index="court">
              <template slot="title">
                <i class="el-icon-sunny"></i>
                <span slot="title">订场管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="user">
              <template slot="title">
                <i class="el-icon-user"></i>
                <span slot="title">用户管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="setting">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span slot="title">场馆配置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="price">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span slot="title">价格配置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="order">
              <template slot="title">
                <i class="el-icon-tickets"></i>
                <span slot="title">预定记录</span>
              </template>
            </el-menu-item>
            <el-menu-item index="record">
              <template slot="title">
                <i class="el-icon-thumb"></i>
                <span slot="title">操作记录</span>
              </template>
            </el-menu-item>
            <el-menu-item index="activity">
              <template slot="title">
                <i class="el-icon-place"></i>
                <span slot="title">活动管理</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to) {
      console.log(to)
      localStorage.setItem('currentIndex', to.path.slice(1))
    },
  },
  data() {
    return {
      username: "", // 当前用户名
      currentIndex: localStorage.getItem('currentIndex') || ''
    };
  },
  created() {
    this.username = localStorage.getItem("username");
  },
};
</script>
<style>
.menu-wrapper {
  height: 100%;
  width: 100%;
}
</style>
