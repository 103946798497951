<template>
  <div class="setting">
    <h2>文章管理</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {

      }
    };
  },
  mounted() {
  },
  methods: {
    saveForm() {

    }
  },
};
</script>

<style scoped>
</style>
