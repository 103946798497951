/*
 * @Author: wangyifei 844650674@qq.com
 * @Date: 2022-11-27 10:22:26
 * @LastEditors: wangyifei 844650674@qq.com
 * @LastEditTime: 2022-11-27 11:32:21
 * @FilePath: \management_system_template\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Layout from "../layout/Index.vue";
import User from "../views/User.vue";
import Court from '../views/Court.vue'
import Setting from '../views/Setting.vue'
import Article from '../views/Article.vue'
import Record from '../views/Record.vue'
import Order from '../views/Order.vue'
import Price from '../views/Price.vue'
import Activity from '../views/Activity.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/layout",
    name: "Layout",
    component: Layout,
    redirect: "/user",
    children: [
      {
        path: "/court",
        component: Court,
      },
      {
        path: "/user",
        component: User,
      },
      {
        path: "/setting",
        component: Setting,
      },
      {
        path: "/article",
        component: Article,
      },
      {
        path: "/record",
        component: Record,
      },
      {
        path: "/order",
        component: Order,
      },
      {
        path: "/price",
        component: Price,
      },
      {
        path: "/activity",
        component: Activity
      }
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
